import React, {
  useContext, useEffect, useCallback, Suspense, lazy, useState, useMemo
} from 'react';
import {
  string, bool, shape, array
} from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  HeaderBody, themePropType, USER_TYPES, PreLoaders
} from '@jotforminc/header-body';
import { getUrlParameter } from '@jotforminc/utils';
import { GrowthAssetManager } from '@jotforminc/growth-asset-manager';
import { initLanguageSwitcherTest } from '../utils/abTests/index';
import {
  runFooterCustomFunction,
  toggleDocumentOverflowProperty,
  attachObsoleteSignupLoadingFunctionToWindow,
  initLoginFlowTrioButtons,
  initGoogleOneTap,
  listenAppSignInOnFailure,
  addBrowserClassNames,
  exceptionallyOverwriteUserDataForFormUser,
  identifyUserForFullstory,
  FS_PROJECT_NAME,
  isFsEnabledOnPage,
  isAiAgentBannerAvailable,
  showLoginFlowModal,
  yieldToMain
} from '../utils';

import {
  useStickyHeader, useEnterprisePromotions, useLazyIntegrationImages, useLazyLoginGroup
} from '../hooks';

import { ModeContext } from './Providers/ModeProvider';
import { UserContext } from './Providers/UserProvider';
import { MobileMenuContext } from './Providers/MobileMenuProvider';
import { JotformActionsContext } from './Providers/JotformActionsProvider';

import LanguageSwitcher from './LanguageSwitcher';
import DeletedFormNotification from './DeletedFormNotification';

import '../styles/header.scss';

const AppPickerBox = lazy(() => import(/* webpackChunkName: "AppPickerBoxComponent" */ './AppPickerBox'));
const LoginGroup = lazy(() => import(/* webpackChunkName: "LoginGroupComponent" */ './LoginGroup/LoginGroup'));

const Header = ({
  fullWidth,
  logoProps,
  logoSideLinkProps,
  customNavItems,
  appPickerProps,
  disableLoginFlow,
  hideLoginButton,
  hideSignupButton,
  forceRTL,
  theme,
  bgColor,
  hideAccountBox,
  replaceAuthButtons,
  language,
  defaultNavItems,
  currentPath,
  menuStyles,
  isEnterprise,
  languageSwitcher,
  contactSalesAbTestVariation,
  contactSalesAbTestVariationURL,
  hostname,
  ...props
}) => {
  const [loadLoginGroup] = useLazyLoginGroup();
  const [isSticky] = useStickyHeader(useContext(ModeContext));
  const [languageSwitcherVisible, setLanguageSwitcherVisible] = useState(false);

  const { logHeaderAction } = useContext(JotformActionsContext);
  const { user, userType, isLoggedIn } = useContext(UserContext);
  const { isMobileMenuActive, isMobileScreen, onMobileMenuButtonClick } = useContext(MobileMenuContext);

  const disableAccountBox = hideAccountBox || !isLoggedIn;
  const { isAppPickerEnabled = false, appPickerItems = [] } = appPickerProps;

  attachObsoleteSignupLoadingFunctionToWindow();
  useEnterprisePromotions(user);
  useLazyIntegrationImages();
  useEffect(() => {
    addBrowserClassNames();
  }, []);

  useEffect(() => {
    toggleDocumentOverflowProperty(isMobileMenuActive);
  }, [isMobileMenuActive]);

  useEffect(() => {
    if (!user) return;
    if (!disableLoginFlow) {
      initLoginFlowTrioButtons();
      initGoogleOneTap(user);
      listenAppSignInOnFailure();
    }
    runFooterCustomFunction(user);
    exceptionallyOverwriteUserDataForFormUser(user, userType === USER_TYPES.FORM_USER);
    initLanguageSwitcherTest(user, languageSwitcher, setLanguageSwitcherVisible);
  }, [user]);

  // A/B Test: landingReactCommonHeader fullstory
  useEffect(() => {
    if (!isFsEnabledOnPage()) return;
    identifyUserForFullstory({
      projectName: FS_PROJECT_NAME,
      username: user?.username,
      isEnabled: true
    });
  }, [user]);

  const AppPickerRenderer = useCallback(() => {
    if (isAppPickerEnabled && !isEmpty(user) && appPickerItems.length > 0) {
      return (
        <Suspense fallback=''>
          <AppPickerBox user={user} {...appPickerProps} />
        </Suspense>
      );
    }
    return <></>;
  }, [user]);

  const handleSignupButtonMobileClick = async () => {
    await yieldToMain();
    logHeaderAction?.({ action: 'click', target: 'header-signup' });
    showLoginFlowModal('s1');
  };

  const preLoaders = useMemo(() => {
    return (
      <PreLoaders
        isLoggedIn={isLoggedIn}
        username={user?.username}
        avatarUrl={user?.avatarUrl}
        hideLoginButton={hideLoginButton}
        hideSignupButton={hideSignupButton}
        contactSalesAbTestVariation={contactSalesAbTestVariation}
        contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
      />
    );
  }, [isLoggedIn, user]);

  const LoginGroupRenderer = useCallback(() => {
    if (disableLoginFlow || replaceAuthButtons) return <></>;
    if (!loadLoginGroup) return preLoaders;
    return (
      <Suspense fallback={preLoaders}>
        <LoginGroup
          {...props}
          hostname={hostname}
          currentPath={currentPath}
          hideLoginButton={hideLoginButton}
          hideSignupButton={hideSignupButton}
          hideAccountBox={disableAccountBox}
          contactSalesAbTestVariation={contactSalesAbTestVariation}
          contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
        />
      </Suspense>
    );
  }, [loadLoginGroup, preLoaders]);

  const updateFavicon = dark => {
    const icon = `favicon-2021-${dark ? 'dark' : 'light'}.png`;
    const link = document.querySelector('[rel="shortcut icon"]');
    if (link) link.href = `https://cdn.jotfor.ms/assets/img/favicons/${icon}`;
  };

  useEffect(() => {
    if (isEnterprise) return;
    const mediaQuery = global.window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = e => updateFavicon(e.matches);

    if (mediaQuery) {
      updateFavicon(mediaQuery.matches);
      mediaQuery.addListener(handleChange);
    }

    return () => mediaQuery.addListener(handleChange);
  }, []);

  const showDeletedFormNotificationVisible = getUrlParameter('utm_campaign') === 'form-deleted';

  const FlyInn = () => {
    return (
      <GrowthAssetManager assetType="flyin" assetProps={{ isSticky }} />
    );
  };

  const ExitModal = () => {
    return (
      <GrowthAssetManager assetType="exit-modal" />
    );
  };

  return (
    <HeaderBody
      user={user}
      theme={theme}
      bgColor={bgColor}
      language={language}
      isSticky={isSticky}
      forceRTL={forceRTL}
      logoProps={logoProps}
      fullWidth={fullWidth}
      isLoggedIn={isLoggedIn}
      currentPath={currentPath}
      isMobileScreen={isMobileScreen}
      customNavItems={customNavItems}
      disableLoginFlow={disableLoginFlow}
      hideLoginButton={hideLoginButton}
      hideSignupButton={hideSignupButton}
      logoSideLinkProps={logoSideLinkProps}
      isMobileMenuActive={isMobileMenuActive}
      replaceAuthButtons={replaceAuthButtons}
      onMobileMenuButtonClick={onMobileMenuButtonClick}
      onMobileSignupButtonClick={handleSignupButtonMobileClick}
      AppPickerRenderer={AppPickerRenderer}
      LoginGroupRenderer={LoginGroupRenderer}
      logHeaderAction={logHeaderAction}
      defaultNavItems={defaultNavItems}
      menuStyles={menuStyles}
      isEnterprise={isEnterprise}
      showAiAgentBanner={isAiAgentBannerAvailable(user)}
      contactSalesAbTestVariation={contactSalesAbTestVariation}
      contactSalesAbTestVariationURL={contactSalesAbTestVariationURL}
      hostname={hostname}
    >
      <>
        {!showDeletedFormNotificationVisible && !isMobileMenuActive && (
          <>
            {languageSwitcherVisible ? (
              <LanguageSwitcher
                isSticky={isSticky}
                languageSwitcher={languageSwitcher}
                setLanguageSwitcherVisible={setLanguageSwitcherVisible}
              />
            ) : (
              <FlyInn />
            )}
            <ExitModal />
          </>
        )}
        {!isMobileMenuActive && showDeletedFormNotificationVisible && (
          <DeletedFormNotification isSticky={isSticky} defaultVisible={true} />
        )}
      </>
    </HeaderBody>
  );
};

Header.propTypes = {
  logoProps: shape({}),
  logoSideLinkProps: shape({}),
  customNavItems: array,
  fullWidth: bool,
  appPickerProps: shape({}),
  disableLoginFlow: bool,
  hideLoginButton: bool.isRequired,
  hideSignupButton: bool.isRequired,
  showDeletedFormNotification: bool,
  forceRTL: bool,
  theme: themePropType.isRequired,
  bgColor: string,
  hideAccountBox: bool,
  replaceAuthButtons: string,
  language: string.isRequired,
  defaultNavItems: array,
  currentPath: string.isRequired,
  menuStyles: shape({}),
  isEnterprise: bool.isRequired,
  languageSwitcher: string,
  contactSalesAbTestVariation: string.isRequired,
  contactSalesAbTestVariationURL: string.isRequired,
  hostname: string.isRequired
};

Header.defaultProps = {
  logoProps: {},
  logoSideLinkProps: {},
  customNavItems: undefined,
  fullWidth: false,
  appPickerProps: {},
  disableLoginFlow: false,
  showDeletedFormNotification: false,
  forceRTL: false,
  bgColor: '',
  hideAccountBox: false,
  replaceAuthButtons: '',
  defaultNavItems: [],
  menuStyles: {},
  languageSwitcher: {
    show: false
  }
};

export default Header;
